<template>
    <div>
      <transition name="vload">
        <div v-show="isLoading" class="Loader">
          <div class="sk-cube-grid">
            <div class="sk-cube sk-cube1"></div>
            <div class="sk-cube sk-cube2"></div>
            <div class="sk-cube sk-cube3"></div>
            <div class="sk-cube sk-cube4"></div>
            <div class="sk-cube sk-cube5"></div>
            <div class="sk-cube sk-cube6"></div>
            <div class="sk-cube sk-cube7"></div>
            <div class="sk-cube sk-cube8"></div>
            <div class="sk-cube sk-cube9"></div>
          </div>
          <!--<i class="fa fa-spinner fa-spin fa-5x fa-fw" style="color:green"></i>-->
        </div>
      </transition>
        <!-- ============================================================== -->
        <!-- Main wrapper - style you can find in pages.scss -->
        <!-- ============================================================== -->
        <div id="main-wrapper">
            <Header></Header>
            <section class="fixed mypage-contents-fixed">
                <div class="container">
                    <div class="row">
                      <MyPageContents class="col-lg-3 col-sm-12" v-on:logout="logout" v-on:delete-account="deleteAccount" :user-data="userData" :validation-errors="deleteAccountValidationErrors"></MyPageContents>
                      <div class="col-lg-9 col-sm-12 mx-auto mypage-right-frame">
                        <div class="form-title-group">
                          <div class="menu-active-mark" style="margin-top: 10px;"/>
                          <div class="form-title"><h1>パスワード変更</h1></div>
                        </div>
                        <div class="menu-bar"/>
                        <p class="form-text">パスワードの再設定が可能です。8~50文字の記号英数で設定してください。</p>
                            <div v-if="hasValidationError" class="error-area">
                                <p v-for="item in allValidationError" :key="item.validationText">
                                    ・{{item.validationText}}
                                </p>
                            </div>
                            <form @submit.prevent="send">
                                <div class="form-group edit-input">
                                    <p v-if="isChanged" class="result-text-success">パスワードの変更が完了しました。</p>
                                    <label class="mypage-form-input">
                                        <input type="password" class="form-control" placeholder="現在のパスワード" v-model="currentPassword">
                                    </label>
                                    <label class="mypage-form-input">
                                        <input type="password" class="form-control" placeholder="新しいパスワード" v-model="password1">
                                    </label>
                                    <label class="mypage-form-input">
                                        <input type="password" class="form-control" placeholder="新しいパスワードをもう一度入力してください" v-model="password2">
                                    </label>
                                </div>
                                <button type="submit" class="btn-md full-width pop-login form-button" :disabled="!isSendEnabled">パスワードを変更する</button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <Footer></Footer>
        </div>
    </div>
</template>
<script>
    import Header from "@/views/components/Header";
    import Footer from "@/views/components/Footer";
    import MyPageContents from "@/views/components/MyPageContents";
    import MyPageMixIn from "@/common/mypage_mixin";

    export default {
        name: "ChangePassword",
        components: {Footer, Header, MyPageContents},
        mixins: [MyPageMixIn],
        data: function() {
            return {
                currentPassword: "",
                password1: "",
                password2: "",
                isChanged: false
            }
        },
        computed: {
            isSendEnabled: {
                get() {
                    return this.currentPassword.length > 0 && this.password1.length > 0 && this.password2.length > 0;
                }
            }
        },
        methods: {
            async send () {
                const response = await this.postApi("user/changePassword", {
                    currentPassword: this.currentPassword,
                    password1: this.password1,
                    password2: this.password2
                });

                if (response) {
                    this.isChanged = true;
                    this.currentPassword = "";
                    this.password1 = "";
                    this.password2 = "";
                } else {
                    this.isChanged = false;
                }
            }
        }
    }
</script>

<style scoped>

</style>
